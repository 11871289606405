import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import about1 from '../images/about1.jpeg'
import OwlCarousel from 'react-owl-carousel'
import naxios from '../nepAxios'
import ContentView from 'react-froala-wysiwyg/FroalaEditorView'
import { TESTIMONIALS } from '../constants/customApi'

const StudentVoice = () => {
  const [testimonials, setTestimonials] = useState(TESTIMONIALS)

  const getTestimonials = async () => {
    try {
      let result = await naxios.get('/testimonial/student-alumni')
      if (result.data.success) {
        setTestimonials(result.data.testimonials)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getTestimonials()
  }, [])

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    navText: ['Pev', 'Next'],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 2,
      },
    },
  }

  return (
    <>
      <div>
        <div class='sitewrapper'>
          <div class='pageheader-wrapper' style={{ backgroundImage: `url(${about1})` }}>
            <div class=' container'>
              <div class='row'>
                <div class='col-12 pageheader-inner'>
                  <h2 class='page-title '>Students Voice</h2>
                  <ul class='bread-crumb'>
                    <li class='breadcrumb-item'>
                      <Link to={'/'}>Home</Link>
                    </li>
                    <li class='breadcrumb-item'>Voice</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class='container'>
            <OwlCarousel {...options} items={3} class='testimonial-center wow animate__fadeIn animate__delay-200ms owl-carousel owl-theme owl-carousel-testimonial'>
              {testimonials &&
                testimonials.map(testi => {
                  return (
                    <div class='item'>
                      <div class='testimonial-block'>
                        <div class='testimonial-stars'>
                          <ul>
                            <li>
                              <i class='fas fa-star'></i>
                            </li>
                            <li>
                              <i class='fas fa-star'></i>
                            </li>
                            <li>
                              <i class='fas fa-star'></i>
                            </li>
                            <li>
                              <i class='fas fa-star'></i>
                            </li>
                            <li>
                              <i class='fas fa-star'></i>
                            </li>
                          </ul>
                        </div>
                        <div class='testimonial-content'>
                          <p class='text-mini-300'>
                            <ContentView model={testi?.content} />
                          </p>
                        </div>
                        <div class='testimonial-info'>
                          <div class='testimonial-name'>
                            <h5>{testi?.name}</h5>
                            <h5>{testi?.primaryTitle}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </>
  )
}

export default StudentVoice
