import React, { useState, useEffect } from 'react'
import naxios from '../nepAxios'
import about1 from '../images/about1.jpeg'
import ContentView from 'react-froala-wysiwyg/FroalaEditorView'
import { useParams } from 'react-router-dom'

function DynamicFaculty() {
  const [faculty, setFaculty] = useState([])
  const [department, setDepartment] = useState(null)
  const { slug } = useParams()

  const getFaculty = async () => {
    try {
      let result = await naxios.get(`/faculty/${slug}`)
      if (result.data.success) {
        setFaculty(result.data.faculty)
        setDepartment(result.data.department)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getFaculty()
  }, [slug])

  return (
    <div>
      <div className='sitewrapper'>
        <div className='pageheader-wrapper' style={{ backgroundImage: `url(${about1})` }}>
          <div className=' container'>
            <div className='row'>
              <div className='col-12 pageheader-inner'>
                <h2 className='page-title '>Faculty and Staffs</h2>
                <ul className='bread-crumb'>
                  <li className='breadcrumb-item'>
                    <a href='./'>Home</a>
                  </li>
                  <li className='breadcrumb-item'>Faculty and Staffs</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='faculty-pg'>
          <section className='sec-main'>
            <div className='container'>
              <div className='row'>
                <div className='sec-title text-center col-lg-9 col-12 mx-auto'>
                  <h3 className='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'visible', 'animation-name': 'bounceInDown' }}>
                    {department?.title}
                  </h3>
                </div>

                <ContentView model={department?.content} />
              </div>
              <div className='row mt-4'>
                <div className='col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms' style={{ visibility: 'visible', 'animation-name': 'fadeInUp' }}>
                  {faculty &&
                    faculty.map(fac => {
                      return (
                        <div className='faculty-block' key={fac._id}>
                          <div className='img'>
                            <img src={`${process.env.REACT_APP_NEPALAYA_BASE}${fac.imageUrl}`} className='faculty-image' alt='' />
                          </div>
                          <div className='content'>
                            <h3 className='name'>{fac.name}</h3>
                            <span className='title'>{fac.title}</span>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default DynamicFaculty
