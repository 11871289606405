import React, { createContext, useState, useEffect } from 'react'
import axios from '../axios'

export const NoticesContext = createContext()

let FETCH_AGAIN = true

const Notices = ({ children, history }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [notices, setNotices] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [unreadNoticesCount, setUnreadNoticesCount] = useState(0)
  const [totalPages, setTotalPages] = useState(0)

  // fetch all the notices of an institution
  const getNotices = async () => {
    setIsLoading(true)
    try {
      let result = await axios.get(`https://supporttoschoolworkspro.digiworkspro.com/events/nepalaya/notices/${currentPage}`, {
        headers: {
          Authorization: 'Bearer $2a$10$gJeGU8WLhVWqHliPUJUBi.3zwmzTP.sfg3owovzzbz7yHVWBjaCx6-nepalaya!',
        },
      })
      if (result.data.success) {
        if (result.data.notices.length > 0) {
          setIsLoading(false)
          setNotices(result.data.notices)
          setTotalPages(Math.ceil(result.data.totalNotices / 9))
        } else {
          if (FETCH_AGAIN) {
            // sleep for 1 second
            await new Promise(resolve => setTimeout(resolve, 1000))
            getNotices()
            FETCH_AGAIN = false
          } else {
            setIsLoading(false)
          }
        }
      } else {
        setIsLoading(false)
        setNotices([])
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  const nextPage = () => {
    setCurrentPage(currPage => currPage + 1)
  }

  const previousPage = () => {
    setCurrentPage(currPage => currPage - 1)
  }

  useEffect(() => {
    getNotices()
  }, [currentPage])

  return (
    <NoticesContext.Provider
      value={{
        isLoading,
        notices,
        currentPage,
        previousPage,
        nextPage,
        totalPages,
      }}
    >
      {children}
    </NoticesContext.Provider>
  )
}

export default Notices
