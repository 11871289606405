import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import SharingClass from '../images/sharingclass.jpeg'
import StudentImage from '../images/student-img.png'
import AboutImage from '../images/about.jpeg'
import PdfImage from '../images/pdf.png'
import about1 from '../images/about1.jpeg'
import naxios from '../nepAxios'
import ContentView from 'react-froala-wysiwyg/FroalaEditorView'

import Semester1to4 from '../pdf/20220106073233.pdf'
import Semester5 from '../pdf/20220106073331.pdf'
import Semester6 from '../pdf/20220106073605.pdf'
import Semester7 from '../pdf/20220106073645.pdf'
import { Program } from '../ImageExport'
import { Information as InformationContext } from '../contexts/GeneralInformation'
import { COURSES } from '../constants/customApi'

function Course() {
  const { slug } = useParams()
  const [course, setCourse] = useState(null)
  const { general } = useContext(InformationContext)

  const getCourse = async () => {
    try {
      let result = await naxios.get(`/course/${slug}`)
      if (result.data.success) {
        setCourse(result.data.course)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const courses = COURSES
    let course = courses.find(c => c.slug === slug)
    if (course) {
      setCourse(course)
    }

    getCourse()
  }, [slug])

  const renderCourseContent = contents => {
    return (
      <div class='row g-5 row-col-auto'>
        {contents.map(con => {
          return (
            <div class='col-md-3 d-flex justify-content-center '>
              <a href={`${process.env.REACT_APP_NEPALAYA_BASE}${con.docUrl}`} target='_blank' rel='noreferrer'>
                <img src={PdfImage} alt='' style={{ height: '120px' }} />
                <p class='text-center'>
                  <i>{con.docName}</i>
                </p>
              </a>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div>
      <div class='sitewrapper'>
        <div class='pageheader-wrapper pageheader-course' style={{ backgroundImage: `url(${`${process.env.REACT_APP_NEPALAYA_BASE}${course?.imageUrl}`})` }}>
          <div class=' container'>
            <div class='row'>
              <div class='col-12 pageheader-inner'>
                <h2 class='page-title '>{course?.title}</h2>
                <div class='course-header-desc'>
                  <div class='course-detail'>
                    <div class='icon'>
                      <i class='fas fa-history'></i>
                    </div>
                    <div class='title'>Duration</div>
                    <div class='content'>{course?.courseDuration}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='single-course-pg'>
          <section class='course-info-sec sec-main'>
            <div class='container'>
              <div class='row'>
                <div class='col-lg-9'>
                  <div class='course-info'>
                    <div class='title'>
                      <h3>{course?.title}</h3>
                    </div>

                    <ContentView model={course?.content} />
                    <div class='content' style={{ 'text-align': 'justify' }}>
                      <ContentView model={course?.content} />
                    </div>
                  </div>
                  <div class='row'></div>
                </div>
                <div class='col-lg-3'>
                  <div class='course-extra-info'>
                    <div class='title'>
                      <h3>Course Info</h3>
                    </div>
                    <ul>
                      <li>
                        <h5>Total Credit</h5>
                        <span>{course?.totalCredit}</span>
                      </li>
                      <li>
                        <h5>Course Duration</h5>
                        <span>{course?.courseDuration}</span>
                      </li>
                      <li>
                        <h5>Program Duration</h5>
                        <span>{course?.degreeDuration}</span>
                      </li>
                      <li>
                        <h5>Course Type</h5>
                        <span>{course?.courseType}</span>
                      </li>
                      <li>
                        <h5>Internship</h5>
                        <span>{course?.internship}</span>
                      </li>

                      <li>
                        <h5>Total Cost</h5>
                        <span>{course?.totalCost}</span>
                      </li>
                    </ul>
                  </div>
                  <div class='course-contact'>
                    <div class='title'>
                      <h3>Get In Touch</h3>
                    </div>
                    <div class='content'>
                      <p>Have questions about studying at Nepalaya? We invite you to connect with our Admissions team to learn more.</p>
                    </div>
                    <a href='https://nepalaya.edu.np/contact' class='link-btn btn-style-5'>
                      Contact Us
                    </a>
                  </div>
                  <div class='apply-btn mb-5 mt-5'>
                    <a href='https://nepalaya.edu.np/admission' class='link-btn btn-style-1'>
                      Apply Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class='tour-sec-big wow animate__fadeInLeft animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
            <div class='container'>
              <div class='row align-items-center'>
                <div class='col-lg-7'>
                  <div class='sec-title'>
                    <h3 class='title-big text-white wow animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                      Already part of Nepalaya!
                    </h3>
                    <p class='tour-sub-title'>Login to our E-PORTAL, an extension for quality education.</p>
                  </div>
                </div>
                <div class='col-lg-5'>
                  <div class='tour-btn'>
                    <a href='https://schoolworkspro.com/' class='link-btn btn-style-4 m-2'>
                      Go to E-PORTAL
                    </a>
                    <a href='#' class='link-btn btn-style-4 m-2'>
                      Get More Info
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class='course-content-sec sec-main bg-grey'>
            <div class='container'>
              <div class='row'>
                <div class='sec-title text-center'>
                  <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                    Course Content
                  </h3>
                </div>
              </div>
              {course?.courseContent && course?.courseContent.length > 0 && renderCourseContent(course.courseContent)}
            </div>
          </section>
          <section class='cta-sec sec-main bg-sec' style={{ backgroundImage: `url(${about1})` }}>
            <div class='blue-overlay'></div>
            <div class='container'>
              <div class='row py-4'>
                <div class='col-lg-8 col-md-10 col-sm-12 mx-auto text-center'>
                  <div class='sec-title'>
                    <h5 class='title-small text-white wow animate__fadeInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                      Learn about your options.
                    </h5>
                    <h2 class='title-big text-white wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                      IS A CAREER IN {course?.title} PROGRAM RIGHT FOR YOU? FIND OUT!
                    </h2>
                  </div>
                </div>
                <div class='col-lg-8 col-md-10 col-sm-12 mx-auto text-center wow animate__fadeInUp animate__delay-500ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                  <div class='cta-contact-box'>
                    <div class='cta-icon'>
                      <i class='fa fa-comments'></i>
                    </div>
                    <div class='cta-content'>
                      <div class='cta-desc text-white'>Talk to an Expert!</div>
                      <div class='cta-title '>
                        <a href='tel:015375203' class='text-white'>
                          {course?.contact}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class='cta-btn mt-4'>
                    <a href='https://schoolworkspro.com/npl/admission' class='link-btn btn-style-2'>
                      Schedule Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class='carrer-pathway-sec sec-main'>
            <div class='container'>
              <div class='row'>
                <div class='col-lg-6'>
                  <div class='image image-top wow animate__fadeInLeft animate__delay-500ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                    <img src={AboutImage} alt='' />
                  </div>
                </div>
                <div class='col-lg-6'>
                  <div class='sec-title '>
                    <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                      How this course will help the students?
                    </h3>
                  </div>
                  <div class='list'>
                    <ul style={{ 'text-align': 'justify' }}>
                      {course?.howHelp.split(';').map((how, index) => {
                        return <li key={index}>{how}</li>
                      })}
                      {/* <ul>
                        <li>
                          Practical hands-on experiments are the most important requirement in any IT courses, for the purpose we have well equipped modern labs which will be used to illustrate how
                          the theoretical concepts learned on class applies to the real context and the challenges it brings. These are vital skills for gaining entry into organizations, which demands
                          confident and technically equipped IT graduates.
                        </li>
                        <li>
                          Students will have the opportunity to work on real-world projects via ‘Industry Projects’ programs, focusing on specialist areas of their choice, including: web technology,
                          database or software development and to gain real-world work experience which is conducted by college every semester.
                        </li>
                        <li>Experience in co-operative working, planning and decision making through various group projects and class activities.</li>
                        <li>Development of personnel professional level skills in technical writing, report writing, public speaking and presentation.</li>
                      </ul> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class='carrer-opportunities sec-main  wow animate__fadeInUp animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
            <div class='container'>
              <div class='row'>
                <div class='carrer-wrapper'>
                  <div class='carrer-content'>
                    <h3 class='title'>Carrer Pathways</h3>
                    <div class='content'>
                      <p></p>
                      <p>{course?.careerPathway}</p>
                      <p></p>
                    </div>
                  </div>
                  <div class='career-image '>
                    <img src={StudentImage} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Course
