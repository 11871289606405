import React, { useState, useEffect } from 'react'
import Principal from '../images/principal.jpeg'
import Avatar from '../images/avatar.png'
import about1 from '../images/about1.jpeg'
import { Advisor, Team } from '../ImageExport'
import naxios from '../nepAxios'
import { ADVISOR, BOD_DATA } from '../constants/customApi'

function BOD() {
  const [about, setAbout] = useState(null)
  const [bod, setBod] = useState(BOD_DATA)
  const [advisor, setAdvisor] = useState(ADVISOR)

  const getAdvisor = async () => {
    try {
      let result = await naxios.get('/advisors')
      if (result.data.success) {
        setAdvisor(result.data.advisors)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getBOD = async () => {
    try {
      let result = await naxios.get('/bod')
      if (result.data.success) {
        setBod(result.data.bod)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getAdvisor()
    getBOD()
  }, [])

  return (
    <div>
      <div class='sitewrapper'>
        <div class='pageheader-wrapper' style={{ backgroundImage: `url(${about1})` }}>
          <div class=' container'>
            <div class='row'>
              <div class='col-12 pageheader-inner'>
                <h2 class='page-title '>Board Of Directors</h2>
                <ul class='bread-crumb'>
                  <li class='breadcrumb-item'>
                    <a href='https://nepalaya.edu.np'>Home</a>
                  </li>
                  <li class='breadcrumb-item'>Board of Directors</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class='bod-pg'>
          <section class='bod-sec sec-main pt-1'>
            <div class='container'>
              <div class='row bod-block-wrapper'>
                <div class='sec-title text-center'>
                  <h3 class='title-big wow animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none' }}>
                    Team Nepalaya
                  </h3>
                </div>
                <p class='pb-4 text-justify'>
                  Currently, Nepalaya College is being led by the team of proven success, visionary, experienced management experts, educators and entrepreneurs to prepare Nepalaya for tomorrow to
                  prove it as A LOCAL GLOBAL SCHOOL. Our management team is entirely focused on facilitating the students with no compromise in quality education to produce globally saleable skilled
                  human resources that can benefit not only the nation but also the entire world. And, to make our vision come true, we have recently partnered with corporate houses, placement
                  partners and renowned international education providers to deliver skill-based courses ever in demand these days.
                </p>

                {bod &&
                  bod.length > 0 &&
                  bod.map(b => {
                    return (
                      <div
                        class='col-lg-4 col-md-6 col-sm-8 col-12 my-4 my-lg-1 wow animate__animated animate__fadeInUp animate__delay-200ms'
                        style={{ visibility: 'hidden', 'animation-name': 'none' }}
                      >
                        <div class='bod-block'>
                          <div class=''>
                            <img src={`${process.env.REACT_APP_NEPALAYA_BASE}${b.imageUrl}`} className='bod-image' alt='' />
                          </div>
                          <div class='content text-center'>
                            <h5 class='name'>{b.name}</h5>

                            <p class='text-mini'>{b.shortTitle}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </section>

          <section class='bod-sec sec-main pt-1'>
            <div class='container'>
              <div class='row bod-block-wrapper'>
                <div class='sec-title text-center'>
                  <h3 class='title-big wow animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none' }}>
                    ADVISORY Team
                  </h3>
                </div>
                <p class='pb-4 text-justify'>
                  A team of highly influential personalities with extensive knowledge and work-based experience in their respective sectors like education, service, business and corporate
                  administration are guiding us in pursuing our institutional goal to be a true LOCAL GLOBAL SCHOOL in near future.{' '}
                </p>
                {advisor &&
                  advisor.length > 0 &&
                  advisor.map(b => {
                    return (
                      <div
                        class='col-lg-4 col-md-6 col-sm-8 col-12 my-4 my-lg-1 wow animate__animated animate__fadeInUp animate__delay-200ms'
                        style={{ visibility: 'hidden', 'animation-name': 'none' }}
                      >
                        <div class='bod-block'>
                          <div class=''>
                            <img src={`${process.env.REACT_APP_NEPALAYA_BASE}${b.imageUrl}`} className='bod-image' alt='' />
                          </div>
                          <div class='content text-center'>
                            <h5 class='name'>{b.name}</h5>

                            <p class='text-mini'>{b.shortTitle}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default BOD
