import React from "react";
import about1 from "../../images/about1.jpeg";

function facultyBSCCSIT() {
  return (
    <div>
      <div class="sitewrapper">
        <div
          class="pageheader-wrapper"
          style={{ backgroundImage: `url(${about1})` }}
        >
          <div class=" container">
            <div class="row">
              <div class="col-12 pageheader-inner">
                <h2 class="page-title "> BSc CSIT Faculty</h2>
                <ul class="bread-crumb">
                  <li class="breadcrumb-item">
                    <a href="./">Home</a>
                  </li>
                  <li class="breadcrumb-item">BSc CSIT Faculty</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <section class="about-us-sec sec-main pb-0">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="sec-title text-center">
                  {/* <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'visible', 'animation-name': 'bounceInDown' }}>
                    ECA Result
                  </h3> */}

                  <div class="row">
                    <div class="col-lg-12">
                      <div class="sec-title text-center"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 ms-auto pb-5"></div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default facultyBSCCSIT;
