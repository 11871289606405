import React from 'react'
import { Link } from 'react-router-dom'
import SharingClass from '../images/sharingclass.jpeg'
import StudentImage from '../images/student-img.png'
import AboutImage from '../images/about.jpeg'
import PdfImage from '../images/pdf.png'
import BCAPDF from '../pdf/20220106075517.pdf'
import about1 from '../images/about1.jpeg'
import { Program } from '../ImageExport'

function BCA() {
  return (
    <div>
      <div class='sitewrapper'>
        <div class='pageheader-wrapper pageheader-course' style={{ backgroundImage: `url(${Program.BCA})` }}>
          <div class=' container'>
            <div class='row'>
              <div class='col-12 pageheader-inner'>
                <h2 class='page-title '>BCA PROGRAM</h2>
                <div class='course-header-desc'>
                  <div class='course-detail'>
                    <div class='icon'>
                      <i class='fas fa-history'></i>
                    </div>
                    <div class='title'>Duration</div>
                    <div class='content'>4 Years</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='single-course-pg'>
          <section class='course-info-sec sec-main'>
            <div class='container'>
              <div class='row'>
                <div class='col-lg-9'>
                  <div class='course-info'>
                    <div class='title'>
                      <h3>BCA PROGRAM</h3>
                    </div>
                    <div class='content' style={{ 'text-align': 'justify' }}>
                      <p>
                        BCA is a four-year undergraduate degree in the field of computer applications or computer science. Bachelor in computer application (BCA) is one of the courses that connects
                        students to a promising career in computing and IT. The course helps students to develop a lot practical skills in different areas of IT and computing. The students interested
                        in computer field and who wants to work in the IT sector as a programmer or software developer can study this course. After BCA, students are eligible to study (MCA) master in
                        computer application. It is a common degree for CS/ IT universities and an alternative to the engineering counterpart, BE/B. Tech in Computer Science/IT which also takes four
                        years. It is a technical degree that prepares students’ career in the field of computer application and software development.
                      </p>

                      <p>
                        <strong>Course Specialization:</strong> Computer Programming | Database Management | Cyber Security | Web Development | Networking
                      </p>

                      <p>
                        <strong>Elective Courses:</strong>
                        <br />
                        • &nbsp;IT in Banking&nbsp;
                        <br />
                        • &nbsp;Applied Psychology
                        <br />
                        • &nbsp;Geographical Information System&nbsp;
                        <br />
                        • &nbsp;Hotel Information System&nbsp;
                        <br />
                        • &nbsp;Enterprise Resource Planning&nbsp;
                        <br />
                        • &nbsp;Knowledge Engineering&nbsp;
                        <br />
                        • &nbsp;Advanced Dot Net Technology
                        <br />
                        • &nbsp;Database Programming&nbsp;
                        <br />
                        • &nbsp;Database Administration&nbsp;
                        <br />
                        • &nbsp;Network Administration
                        <br />
                        &nbsp;
                      </p>
                    </div>
                  </div>
                  <div class='row'></div>
                </div>
                <div class='col-lg-3'>
                  <div class='course-extra-info'>
                    <div class='title'>
                      <h3>Course Info</h3>
                    </div>
                    <ul>
                      <li>
                        <h5>Total Credit</h5>
                        <span>126 Credits</span>
                      </li>
                      <li>
                        <h5>Course Duration</h5>
                        <span>4 Years</span>
                      </li>
                      <li>
                        <h5>Program Duration</h5>
                        <span>4 Yrs.| 8 Semesters</span>
                      </li>
                      <li>
                        <h5>Course Type</h5>
                        <span>Theory &amp; Practical</span>
                      </li>
                      <li>
                        <h5>Internship</h5>
                        <span>6 months internship (unpaid)</span>
                      </li>

                      <li>
                        <h5>Total Cost</h5>
                        <span>Rs. 6,00,000</span>
                      </li>
                    </ul>
                  </div>
                  <div class='course-contact'>
                    <div class='title'>
                      <h3>Get In Touch</h3>
                    </div>
                    <div class='content'>
                      <p>Have questions about studying at Nepalaya? We invite you to connect with our Admissions team to learn more.</p>
                    </div>
                    <Link to={'/contact'} class='link-btn btn-style-5'>
                      Contact Us
                    </Link>
                  </div>
                  <div class='apply-btn mb-5 mt-5'>
                    <a href={'https://schoolworkspro.com/npl/admission'} class='link-btn btn-style-1'>
                      Apply Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class='tour-sec-big wow animate__fadeInLeft animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
            <div class='container'>
              <div class='row align-items-center'>
                <div class='col-lg-7'>
                  <div class='sec-title'>
                    <h3 class='title-big text-white wow animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                      Already part of Nepalaya!
                    </h3>
                    <p class='tour-sub-title'>Login to our E-PORTAL, an extension for quality education.</p>
                  </div>
                </div>
                <div class='col-lg-5'>
                  <div class='tour-btn'>
                    <a href='https://schoolworkspro.com/' class='link-btn btn-style-4 m-2'>
                      Go to E-PORTAL
                    </a>
                    <a href='#' class='link-btn btn-style-4 m-2'>
                      Get More Info
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class='course-content-sec sec-main bg-grey'>
            <div class='container'>
              <div class='row'>
                <div class='sec-title text-center'>
                  <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                    Course Content
                  </h3>
                </div>
              </div>

              <div class='row g-5 row-col-auto'>
                <div class='col-md-3 d-flex justify-content-center '>
                  <a href={BCAPDF} target='_blank'>
                    <img src={PdfImage} alt='' style={{ height: '120px' }} />
                    <p class='text-center'>
                      <i>Syllabus</i>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section class='cta-sec sec-main bg-sec' style={{ backgroundImage: `url(${about1})` }}>
            <div class='blue-overlay'></div>
            <div class='container'>
              <div class='row py-4'>
                <div class='col-lg-8 col-md-10 col-sm-12 mx-auto text-center'>
                  <div class='sec-title'>
                    <h5 class='title-small text-white wow animate__fadeInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                      Learn about your options.
                    </h5>
                    <h2 class='title-big text-white wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                      IS A CAREER IN BCA PROGRAM RIGHT FOR YOU? FIND OUT!
                    </h2>
                  </div>
                </div>
                <div class='col-lg-8 col-md-10 col-sm-12 mx-auto text-center wow animate__fadeInUp animate__delay-500ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                  <div class='cta-contact-box'>
                    <div class='cta-icon'>
                      <i class='fa fa-comments'></i>
                    </div>
                    <div class='cta-content'>
                      <div class='cta-desc text-white'>Talk to an Expert!</div>
                      <div class='cta-title '>
                        <a href='tel:015375203' class='text-white'>
                          {' '}
                          +977 – 1- 5375203 | 5234279{' '}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class='cta-btn mt-4'>
                    <a href='https://schoolworkspro.com/npl/admission' class='link-btn btn-style-2'>
                      Schedule Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class='carrer-pathway-sec sec-main'>
            <div class='container'>
              <div class='row'>
                <div class='col-lg-6'>
                  <div class='image image-top wow animate__fadeInLeft animate__delay-500ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                    <img src={AboutImage} alt='' />
                  </div>
                </div>
                <div class='col-lg-6'>
                  <div class='sec-title '>
                    <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                      How this course will help the students?
                    </h3>
                  </div>
                  <div class='list'>
                    <ul style={{ 'text-align': 'justify' }}>
                      <ul>
                        <li>
                          This is an integrative course, with an opportunity to know and learn about several other courses than just computer related course. Students after completion of the course
                          can be associated with different genres of industries. Along with being part of IT industry, students can also be associated with industries like Banks, NGO’s and INGO’s and
                          many others.
                        </li>
                        <li>
                          The course consists of several hand-on and on the filed activities rather than just classroom-based teaching and learning. This enables students to gain real world and
                          practical based skills to be easily marketable for industry.
                        </li>
                        <li>
                          Internship and project works are key aspects of this course. Students via these on-the-site courses get ample opportunity to learn about the working procedure of the
                          industry. It provides prospect to the students to know about industry during their academic years itself.
                        </li>
                        <li>Experience in co-operative working, planning and decision making through various group projects and class activities.</li>
                        <li>
                          &nbsp;Development of personnel professional level skills in technical writing, report writing, public speaking and presentation.
                          <br />
                          &nbsp;
                        </li>
                      </ul>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class='carrer-opportunities sec-main  wow animate__fadeInUp animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
            <div class='container'>
              <div class='row'>
                <div class='carrer-wrapper'>
                  <div class='carrer-content'>
                    <h3 class='title'>Carrer Pathways</h3>
                    <div class='content'>
                      <p></p>
                      <p>Software Engineer | Database Manager | Cyber Security Expert | Networking Expert | Web / Application Developer | System Administrator</p>
                      <p></p>
                    </div>
                  </div>
                  <div class='career-image '>
                    <img src={StudentImage} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default BCA
