import { useState, useMemo } from 'react'
import about1 from '../images/about1.jpeg'
import about2 from '../images/about2.jpeg'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import naxios from '../nepAxios'

function Gallery() {
  const [gallery, setGallery] = useState([])
  const [currentSelection, setCurrentSelection] = useState('')

  const getAllGallery = async () => {
    try {
      let result = await naxios.get('/gallery')
      if (result.data.success) {
        setGallery(result.data.gallery)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getAllGallery()
  }, [])

  const showImages = useMemo(() => {
    let finalGallery = []
    if (gallery) {
      if (currentSelection) {
        let currentGallery = gallery.find(g => g.slug === currentSelection)
        finalGallery = currentGallery.media
      } else {
        gallery.forEach(g => {
          finalGallery = [...finalGallery, ...g.media]
        })
      }
    }
    return finalGallery
  }, [currentSelection, gallery])

  const changeFilter = filter => {
    setCurrentSelection(filter)
  }

  return (
    <div>
      <div className='sitewrapper'>
        <div className='pageheader-wrapper' style={{ backgroundImage: `url(${about1})` }}>
          <div className=' container'>
            <div className='row'>
              <div className='col-12 pageheader-inner'>
                <h2 className='page-title '>Gallery</h2>
                <ul className='bread-crumb'>
                  <li className='breadcrumb-item'>
                    <Link to={'/'}>Home</Link>
                  </li>
                  <li className='breadcrumb-item'>Gallery</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='container'>
        <div class='row'>
          <div class='intro'>
            {/* <h2 class='text-center'>Gallery</h2> */}
            {/* <p class='text-center'>Gallery</p> */}
          </div>

          <div className='row my-2'>
            <div
              // Center div
              className='col-md-12 d-flex justify-content-center'
            >
              <button className={` btn ${currentSelection === '' ? 'btn-primary' : 'btn-secondary'} fs-3 me-2`} onClick={() => changeFilter('')}>
                All
              </button>
              {gallery &&
                gallery.map((g, index) => {
                  return (
                    <button key={g.slug} className={` btn ${currentSelection === g.slug ? 'btn-primary' : 'btn-secondary'} fs-3 me-2`} onClick={() => changeFilter(g.slug)}>
                      {g.title}
                    </button>
                  )
                })}
            </div>
          </div>
          <div class='row photos'>
            {showImages &&
              showImages.map((image, index) => {
                return (
                  <div key={image} class='col-sm-6 col-md-4 col-lg-3 item mt-4'>
                    <a href={`${process.env.REACT_APP_NEPALAYA_BASE}${image}`} data-lightbox='photos'>
                      <img class='img-fluid' src={`${process.env.REACT_APP_NEPALAYA_BASE}${image}`} alt={'gallery'} />
                    </a>
                  </div>
                )
              })}
          </div>
          {/* space  */}
          <div className='my-4'></div>
        </div>
      </div>
    </div>
  )
}

export default Gallery
