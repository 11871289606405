import React from 'react'

function Inquiry() {
    return (
        <div>
                                    

            </div>
            )
}

            export default Inquiry