import { createContext, useReducer, useEffect } from 'react'
import naxios from '../nepAxios'
import { GENERAL } from '../constants/customApi'

export const Information = createContext()

export const informationReducer = (state, action) => {
  switch (action.type) {
    case 'FETCHED':
      return { general: action.payload, ready: true }
    default:
      return { ...state, ready: true, general: GENERAL.info }
  }
}

export const InformationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(informationReducer, {
    general: GENERAL.info,
    ready: false,
  })

  const getGeneralInformation = async () => {
    try {
      const response = await naxios.get('/information/general')
      const { data } = response
      if (data.success) {
        dispatch({ type: 'FETCHED', payload: data.info })
      }
    } catch (e) {}
  }

  useEffect(() => {
    getGeneralInformation()
  }, [])

  return <Information.Provider value={{ ...state, dispatch }}>{children}</Information.Provider>
}
