import React from 'react'
import '../App.css'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import about2 from '../images/about2.jpeg'
import about1 from '../images/about1.jpeg'
import mission from '../images/mission.png'
import vision from '../images/vision.png'
import motto from '../images/motto.png'
import Testimonial from '../images/testimonial-bg.jpeg'
import PhoneWhite from '../images/phone-1-white.png'
import { Program, Students } from '../ImageExport'
import naxios from '../nepAxios'

import 'owl.carousel/dist/assets/owl.theme.default.css'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useState, useContext } from 'react'
import { Information as InformationContext } from '../contexts/GeneralInformation'
import ContentView from 'react-froala-wysiwyg/FroalaEditorView'
import { COURSES, TESTIMONIALS } from '../constants/customApi'

function App() {
  const [courses, setCourses] = useState(COURSES)
  const { general } = useContext(InformationContext)
  const [testimonials, setTestimonials] = useState(TESTIMONIALS)

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 2,
      },
    },
  }

  const options2 = {
    margin: 0,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  }

  const getAllCourses = async () => {
    try {
      let result = await naxios.get('/courses/all')
      if (result.data.success) {
        setCourses(result.data.courses)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getAllTestimonial = async () => {
    try {
      let result = await naxios.get('/testimonial')
      if (result.data.success) {
        setTestimonials(result.data.testimonials)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const renderTestimonials = allTestimonials => {
    return (
      <OwlCarousel {...options} items={2} class='testimonial-center wow animate__fadeIn animate__delay-200ms owl-carousel owl-theme owl-carousel-testimonial'>
        {allTestimonials &&
          allTestimonials.map(testi => {
            return (
              <div class='item' key={testi._id}>
                <div class='testimonial-block'>
                  <div class='testimonial-stars'>
                    <ul>
                      <li>
                        <i class='fas fa-star'></i>
                      </li>
                      <li>
                        <i class='fas fa-star'></i>
                      </li>
                      <li>
                        <i class='fas fa-star'></i>
                      </li>
                      <li>
                        <i class='fas fa-star'></i>
                      </li>
                      <li>
                        <i class='fas fa-star'></i>
                      </li>
                    </ul>
                  </div>
                  <div class='testimonial-content'>
                    <p class='text-mini-300'>
                      <p>
                        <ContentView model={testi?.content} />
                        {/* <span dir='ltr'>One of my proudful decisions</span> */}
                      </p>
                    </p>
                  </div>
                  <div class='testimonial-info'>
                    <div class='testimonial-name'>
                      <h5>{testi?.name}</h5>
                      <p>{testi?.primaryTitle}</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
      </OwlCarousel>
    )
  }

  const renderCoursesCarousel = cour => {
    return (
      <OwlCarousel {...options2} items={5} autoplay={true} className='program-block wow animate__fadeInDown animate__delay-200ms owl-carousel owl-theme owl-carousel-program'>
        {cour.map(c => {
          return (
            <div class='item'>
              <div class='feature-block-1'>
                <Link to={`/course/${c.slug}`} class='feature-link'></Link>
                <div class='feature-img'>
                  <img width='1175' height='783' src={`${process.env.REACT_APP_NEPALAYA_BASE}${c.imageUrl}`} alt={c.title} />
                </div>
                <div class='feature-content'>
                  <div class='feature-icon'>
                    <i class='fa fa-graduation-cap'></i>
                  </div>
                  <div class='feature-title'>
                    <h4 class='program-title'>{c.title}</h4>
                    <span class='program-cat'></span>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </OwlCarousel>
    )
  }

  useEffect(() => {
    getAllCourses()
    getAllTestimonial()
  }, [])

  return (
    <div className='App'>
      <div class='sitewrapper'>
        <section class='programs-sec sec-main'>
          <div class='container-fluid p-0'>
            <div class='row'>
              <div class='sec-title text-center'>
                <h3 class='title-big wow animate__bounceInDown animate__delay-200ms'>Our Academic Programs</h3>
              </div>
            </div>
            {courses && courses.length > 0 && renderCoursesCarousel(courses)}
            <div class='program-btn mt-5 text-center'>
              <Link to={'/academic'} class='link-btn btn-style-2'>
                View All Programs
              </Link>
            </div>
          </div>
        </section>
        <section class='about-us-sec sec-main pb-0 bg-grey'>
          <div class='container'>
            <div class='row'>
              <div class='col-lg-6 col-md-12 ms-auto pb-5'>
                <div class='sec-title'>
                  <h1 class='title-big wow  animate__bounceInDown animate__delay-200ms'>
                    Welcome to
                    <span>Nepalaya</span> School/College
                  </h1>
                </div>
                <div class='about-sec-content wow animate__fadeInLeft animate__delay-500ms'>
                  <p>{general?.homepageText}</p>
                </div>
                <div class='program-btn mt-4'>
                  <Link to={'/about'} class='link-btn btn-style-2'>
                    Read More
                  </Link>
                </div>
                <div class='row founded wow animate__zoomIn animate__delay-500ms'>
                  <div class='col-lg-4 col-md-6 col-12'>
                    <div class='years'>
                      <span>{general?.experience}</span>Year of Success
                    </div>
                  </div>
                  <div class='col-lg-8 col-md-6 col-12'>
                    <div class='text'>
                      Your Local “GLOBAL SCHOOL”
                      <br />
                      Since 1999
                    </div>
                  </div>
                </div>
              </div>
              <div class='col-lg-6 col-md-8 mx-md-auto'>
                <div class='image image-top wow animate__bounceInRight animate__delay-500ms'>
                  <img src={about2} alt='' class='about-img' />
                </div>
              </div>
              <div class='col-lg-12'>
                <div class='row about-vision-wrapper pb-5'>
                  <div class='col-lg-4 text-center'>
                    <div class='about-vision-block'>
                      <div class='img'>
                        <img src={mission} alt='' />
                      </div>
                      <div class='content'>
                        <h3>Mission</h3>
                        <span>
                          <p>Modeling Nepalaya as a practical-based learning institute</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class='col-lg-4 text-center'>
                    <div class='about-vision-block'>
                      <div class='img'>
                        <img src={vision} alt='' />
                      </div>
                      <div class='content'>
                        <h3>Vision</h3>
                        <span>
                          <p>Founding NEPALAYA as a GLOBAL SCHOOL in future.</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class='col-lg-4 text-center'>
                    <div class='about-vision-block'>
                      <div class='img'>
                        <img src={motto} alt='' />
                      </div>
                      <div class='content'>
                        <h3>Motto</h3>
                        <span>
                          <p>Preparing students for SUCCESS.</p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class='cta-sec sec-main bg-sec' style={{ 'background-image': `url(${about1})` }}>
          <div class='blue-overlay'></div>
          <div class='container'>
            <div class='row py-4'>
              <div class='col-lg-8 col-md-10 col-sm-12 mx-auto text-center'>
                <div class='sec-title'>
                  <h2 class='title-big text-white wow  animate__bounceInDown animate__delay-200ms'>Do You NEED Financial Aid? Just Call Us.</h2>
                </div>
              </div>
              <div class='col-lg-8 col-md-10 col-sm-12 mx-auto text-center wow animate__fadeInUp animate__delay-500ms'>
                <div class='cta-contact-box'>
                  <div class='cta-icon'>
                    <i class='fa fa-comments'></i>
                  </div>
                  <div class='cta-content'>
                    <div class='cta-desc text-white'>Talk to an Expert!</div>
                    <div class='cta-title '>
                      <a href='tel:015375203' class='text-white'>
                        {general?.contact}
                      </a>
                    </div>
                  </div>
                </div>
                <div class='cta-btn mt-4'>
                  <Link to={'/contact'} class='link-btn btn-style-2'>
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class='events-sec sec-main'>
          <div class='container'>
            <div class='row'>
              <div class='sec-title text-center'>
                <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms'>School Events</h3>
              </div>
            </div>
            <div class='row event-wrapper wow animate__fadeInDown animate__delay-200ms'>
              <div class='owl-carousel owl-theme owl-carousel-event'></div>
              <div class='event-btn mt-5 text-center'>
                <Link to={'/events'} class='link-btn btn-style-2'>
                  View All Events
                </Link>
              </div>
            </div>
          </div>
        </section>
        <div class='tour-sec wow animate__fadeInLeft animate__delay-200ms'>
          <div class='container'>
            <div class='row align-items-center'>
              <div class='col-lg-9'>
                <div class='sec-title'>
                  <h3 class='title-big text-white wow animate__bounceInDown animate__delay-200ms'>Already part of Nepalaya!</h3>
                  <p class='tour-sub-title'>Login to our E-PORTAL, an extension for quality education.</p>
                </div>
              </div>
              <div class='col-lg-3'>
                <div class='tour-btn'>
                  <a href='https://schoolworkspro.com/' class='link-btn btn-style-4'>
                    Go to E-PORTAL
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class='testimonial-sec sec-bg sec-main parallax-image' style={{ 'background-image': `url(${Students.Group2})` }}>
          <div class='black-overlay'></div>
          <div class='container'>
            <div class='row'>
              <div class='sec-title text-center'>
                <h3 class='title-big text-white wow  animate__bounceInDown animate__delay-200ms'>Our Testimonial</h3>
              </div>
            </div>
            {renderTestimonials(testimonials)}
          </div>
        </section>
      </div>

      <div class='callfixed-wrapper'>
        <div class='clicktocall '>
          <a href='tel:780906-1782'>
            <img src={PhoneWhite} alt='Call Us' />
          </a>
        </div>
      </div>
    </div>
  )

  const previousCarousel = () => {
    return (
      <OwlCarousel {...options2} items={5} autoplay={true} className='program-block wow animate__fadeInDown animate__delay-200ms owl-carousel owl-theme owl-carousel-program'>
        <div class='item'>
          <div class='feature-block-1'>
            <Link to={'/humanities'} class='feature-link'></Link>
            <div class='feature-img'>
              <img width='1175' height='783' src={Program.Humanities} />
            </div>
            <div class='feature-content'>
              <div class='feature-icon'>
                <i class='fa fa-graduation-cap'></i>
              </div>
              <div class='feature-title'>
                <h4 class='program-title'>HUMANITIES</h4>
                <span class='program-cat'></span>
              </div>
            </div>
          </div>
        </div>
        <div class='item'>
          <div class='feature-block-1'>
            <Link to={'/law'} class='feature-link'></Link>
            <div class='feature-img'>
              <img width='1175' height='783' src={Program.Law} />
            </div>
            <div class='feature-content'>
              <div class='feature-icon'>
                <i class='fa fa-graduation-cap'></i>
              </div>
              <div class='feature-title'>
                <h4 class='program-title'>LAW</h4>
                <span class='program-cat'></span>
              </div>
            </div>
          </div>
        </div>
        <div class='item'>
          <div class='feature-block-1'>
            <Link to={'/management'} class='feature-link'></Link>
            <div class='feature-img'>
              <img width='1175' height='783' src={Program.Management} />
            </div>
            <div class='feature-content'>
              <div class='feature-icon'>
                <i class='fa fa-graduation-cap'></i>
              </div>
              <div class='feature-title'>
                <h4 class='program-title'>MANAGEMENT</h4>
                <span class='program-cat'></span>
              </div>
            </div>
          </div>
        </div>
        <div class='item'>
          <div class='feature-block-1'>
            <Link to={'/science'} class='feature-link'></Link>
            <div class='feature-img'>
              <img width='1175' height='783' src={Program.Science1} />
            </div>
            <div class='feature-content'>
              <div class='feature-icon'>
                <i class='fa fa-graduation-cap'></i>
              </div>
              <div class='feature-title'>
                <h4 class='program-title'>SCIENCE</h4>
                <span class='program-cat'></span>
              </div>
            </div>
          </div>
        </div>
        <div class='item'>
          <div class='feature-block-1'>
            <Link to={'/mbs'} class='feature-link'></Link>
            <div class='feature-img'>
              <img width='1175' height='783' src={Program.MBS} />
            </div>
            <div class='feature-content'>
              <div class='feature-icon'>
                <i class='fa fa-graduation-cap'></i>
              </div>
              <div class='feature-title'>
                <h4 class='program-title'>MBS PROGRAM</h4>
                <span class='program-cat'></span>
              </div>
            </div>
          </div>
        </div>
        <div class='item'>
          <div class='feature-block-1'>
            <Link to={'/bca'} class='feature-link'></Link>
            <div class='feature-img'>
              <img width='1175' height='783' src={Program.BCA} />
            </div>
            <div class='feature-content'>
              <div class='feature-icon'>
                <i class='fa fa-graduation-cap'></i>
              </div>
              <div class='feature-title'>
                <h4 class='program-title'>BCA PROGRAM</h4>
                <span class='program-cat'></span>
              </div>
            </div>
          </div>
        </div>
        <div class='item'>
          <div class='feature-block-1'>
            <Link to={'/bbs'} class='feature-link'></Link>
            <div class='feature-img'>
              <img width='1175' height='783' src={Program.BBS} />
            </div>
            <div class='feature-content'>
              <div class='feature-icon'>
                <i class='fa fa-graduation-cap'></i>
              </div>
              <div class='feature-title'>
                <h4 class='program-title'>BBS PROGRAM</h4>
                <span class='program-cat'></span>
              </div>
            </div>
          </div>
        </div>
        <div class='item'>
          <div class='feature-block-1'>
            <Link to={'/bbm'} class='feature-link'></Link>
            <div class='feature-img'>
              <img width='1175' height='783' src={Program.BBM} />
            </div>
            <div class='feature-content'>
              <div class='feature-icon'>
                <i class='fa fa-graduation-cap'></i>
              </div>
              <div class='feature-title'>
                <h4 class='program-title'>BBM PROGRAM</h4>
                <span class='program-cat'></span>
              </div>
            </div>
          </div>
        </div>
        <div class='item'>
          <div class='feature-block-1'>
            <Link to={'/bscit'} class='feature-link'></Link>
            <div class='feature-img'>
              <img width='1175' height='783' src={Program.CSIT} />
            </div>
            <div class='feature-content'>
              <div class='feature-icon'>
                <i class='fa fa-graduation-cap'></i>
              </div>
              <div class='feature-title'>
                <h4 class='program-title'>BSc CSIT PROGRAM</h4>
                <span class='program-cat'></span>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
    )
  }
}

export default App
