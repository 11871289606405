import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { confirmAlert } from 'react-confirm-alert'
import axios from 'axios'

const localizer = momentLocalizer(moment)
const DnDCalendar = withDragAndDrop(Calendar)

const formats = {
  // for header [Sun,Mon]
  dayFormat: 'dddd',
}

const AllEvents = ({ history }) => {
  const [events, setevents] = useState([])

  const allevent = () => {
    axios
      .get('https://supporttoschoolworkspro.digiworkspro.com/events/nepalaya/all', {
        headers: {
          Authorization: 'Bearer $2a$10$gJeGU8WLhVWqHliPUJUBi.3zwmzTP.sfg3owovzzbz7yHVWBjaCx6-nepalaya!',
        },
      })
      .then(async result => {
        if (result.data.success) {
          let finalEvents = result.data.events.map(data => ({
            end: moment(data.endDate).add(1, "days").toDate(),
            start: moment(data.startDate).toDate(),
            title: data.eventTitle,
            id: data._id,
          }))
          setevents(finalEvents)
        }
      })
      .catch(error => {})
  }

  // view routine
  const viewEventDetails = data => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='confirmation-box'>
            <h6 className='title text-secondary'>{`${moment(data.start).format('ddd, MMM DD, YYYY')} - ${moment(moment(data.end).subtract(1, 'days').toDate()).format('ddd, MMM DD, YYYY')}`}</h6>

            <h5>{data.title}</h5>
          </div>
        )
      },
    })
  }

  useEffect(() => {
    allevent()
  }, [])

  return (
    <section>
      <div className='container sitewrapper'>
        <div className='row mt-5'>
          <div className='col-lg-12 col-md-12 col-sm-12 mt-5'>
            <div className='card has-shadow mt-5'>
              <div className='card-body p-3 mt-5'>
                <div id='calendar'>
                  <DnDCalendar selectable={false} resizable={false} events={events} localizer={localizer} formats={formats} style={{ height: '100vh' }} onSelectEvent={viewEventDetails} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='m-4'></div>
    </section>
  )
}

export default AllEvents
