import React from 'react'
import { Link } from 'react-router-dom'
import about1 from '../images/about1.jpeg'
import NibandhanLekhan2047 from '../images/eca/nibandhan-lekhan-2079.jpeg'
import SpeechCompetition2079 from '../images/eca/speech_competition-2079.jpeg'
import QuizCompetition2079Third from '../images/eca/quicompetition2079_third.jpeg'
import QuizCompetition2079Second from '../images/eca/quicompetition2079_second.jpeg'
import QuizCompetition2079First from '../images/eca/quicompetition2079_first.jpeg'

function ECA() {
  return (
    <div>
      <div class='sitewrapper'>
        <div class='pageheader-wrapper' style={{ backgroundImage: `url(${about1})` }}>
          <div class=' container'>
            <div class='row'>
              <div class='col-12 pageheader-inner'>
                <h2 class='page-title '>ECA Result</h2>
                <ul class='bread-crumb'>
                  <li class='breadcrumb-item'>
                    <Link to={'/'}>Home</Link>
                  </li>
                  <li class='breadcrumb-item'>ECA Result</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section class='about-us-sec sec-main pb-0'>
          <div class='container'>
            <div class='row'>
              <div class='col-lg-12 col-md-12 ms-auto pb-5'>
                <div class='about-sec-content wow animate__fadeInLeft animate__delay-500ms' style={{ 'text-align': 'justify', visibility: 'visible', 'animation-name': 'fadeInLeft' }}>
                  {/* <p>Winner Futsal Tournament</p> */}
                  <div class='row'>
                    <div class='col-lg-12'>
                      <div class='sec-title text-center'>
                        <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'visible', 'animation-name': 'bounceInDown' }}>
                          Quiz Competition 2079
                        </h3>
                        <img src={QuizCompetition2079First} alt='Quiz Competitions 2079 First' />
                        <div className='row mt-4'>
                          <div className='col-md-6'>
                            <img src={QuizCompetition2079Second} alt='Quiz Competitions 2079 Second' />
                          </div>
                          <div className='col-md-6'>
                            <img src={QuizCompetition2079Third} alt='Quiz Competitions 2079 Third' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p>&nbsp;</p>
                </div>
              </div>
              <div class='col-lg-12'>
                <div class='sec-title text-center'>
                  {/* <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'visible', 'animation-name': 'bounceInDown' }}>
                    ECA Result
                  </h3> */}

                  <div class='row'>
                    <div class='col-lg-12'>
                      <div class='sec-title text-center'>
                        <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'visible', 'animation-name': 'bounceInDown' }}>
                          Speech Competition 2079
                        </h3>
                        <img src={SpeechCompetition2079} alt='Speech Competition 2079' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class='col-lg-12 col-md-12 ms-auto pb-5'>
                <div class='about-sec-content wow animate__fadeInLeft animate__delay-500ms' style={{ 'text-align': 'justify', visibility: 'visible', 'animation-name': 'fadeInLeft' }}>
                  {/* <p>Winner Futsal Tournament</p> */}
                  <div class='row'>
                    <div class='col-lg-12'>
                      <div class='sec-title text-center'>
                        <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'visible', 'animation-name': 'bounceInDown' }}>
                          निबन्ध लेखन प्रतियोगिता २०७९
                        </h3>
                        <img src={NibandhanLekhan2047} alt='Nibandhan Lekhan 2079' />
                      </div>
                    </div>
                  </div>

                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default ECA
