import React from 'react'
import { Link } from 'react-router-dom'
import SharingClass from '../images/sharingclass.jpeg'
import about1 from '../images/about1.jpeg'
import about from '../images/about.jpeg'
import StudentImage from '../images/student-img.png'
import PdfImage from '../images/pdf.png'
import { Program } from '../ImageExport'

import Syllabus from '../pdf/20220106083148.pdf'

function MBS() {
  return (
    <div>
      <div class='sitewrapper'>
        <div class='pageheader-wrapper pageheader-course' style={{ backgroundImage: `url(${Program.MBS})` }}>
          <div class=' container'>
            <div class='row'>
              <div class='col-12 pageheader-inner'>
                <h2 class='page-title '>MBS PROGRAM</h2>
                <div class='course-header-desc'>
                  <div class='course-detail'>
                    <div class='icon'>
                      <i class='fas fa-history'></i>
                    </div>
                    <div class='title'>Duration</div>
                    <div class='content'>2 Years</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='single-course-pg'>
          <section class='course-info-sec sec-main'>
            <div class='container'>
              <div class='row'>
                <div class='col-lg-9'>
                  <div class='course-info'>
                    <div class='title'>
                      <h3>MBS PROGRAM</h3>
                    </div>
                    <div class='content' style={{ 'text-align': 'justify' }}>
                      <p>
                        <strong>Course Highlight:</strong>
                        <br />
                        Master of Business Studies (MBS) is two years &amp; four semesters based Graduate Degree program introduced by Tribhuvan University under the faculty of management. The basic
                        objective of the MBS program is to prepare students to compete in the managerial level of professions like; Corporate Managers, Bankers, CEOs, Company Directors, Financial
                        Mangers, Investment Analysis, Business &amp; Financial Consultant to meet the demand of senior level human resources in the local and global organizations, especially in the in
                        the field of management. This course is highly in demand in business and corporate houses including industries, government and non-government sectors in the global market. Two
                        years of full-time study in MBS Degree covers total 60 credit hours of course of study including mandatory project and practical works based on the elective areas that students
                        choose during their final semester of study that enables students to develop their practical knowledge and enhanced management as well as decisional skills.
                      </p>

                      <p>
                        <br />
                        <strong>Eligibility for the Admission in MBS Program</strong>
                        <br />
                        The candidate willing to apply for MBS program must have a bachelor’s degree from any discipline including the Universities and Colleges recognized by Tribhuvan University.
                        Applicants must appear in the CMAT entrance test conducted by Faculty of Management, Tribhuvan University (FOM-TU) and must secure at least 40% marks in CMAT ENTRANCE EXAM in
                        order to be eligible for the admission in this Graduate Program.
                      </p>

                      <p>
                        <br />
                        <strong>Course Specialization:</strong> Accounting | Marketing | Finance | Management
                      </p>

                      <p>&nbsp;</p>
                    </div>
                  </div>
                  <div class='row'></div>
                </div>
                <div class='col-lg-3'>
                  <div class='course-extra-info'>
                    <div class='title'>
                      <h3>Course Info</h3>
                    </div>
                    <ul>
                      <li>
                        <h5>Total Credit</h5>
                        <span>60 Credits</span>
                      </li>
                      <li>
                        <h5>Course Duration</h5>
                        <span>2 Years</span>
                      </li>
                      <li>
                        <h5>Program Duration</h5>
                        <span>2 Years | 4 Semesters</span>
                      </li>
                      <li>
                        <h5>Course Type</h5>
                        <span>No</span>
                      </li>

                      <li>
                        <h5>Total Cost</h5>
                        <span>Rs. 150,000</span>
                      </li>
                    </ul>
                  </div>
                  <div class='course-contact'>
                    <div class='title'>
                      <h3>Get In Touch</h3>
                    </div>
                    <div class='content'>
                      <p>Have questions about studying at Nepalaya? We invite you to connect with our Admissions team to learn more.</p>
                    </div>
                    <Link to={'/contact'} class='link-btn btn-style-5'>
                      Contact Us
                    </Link>
                  </div>
                  <div class='apply-btn mb-5 mt-5'>
                    <a href={'https://schoolworkspro.com/npl/admission'} class='link-btn btn-style-1'>
                      Apply Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class='tour-sec-big wow animate__fadeInLeft animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
            <div class='container'>
              <div class='row align-items-center'>
                <div class='col-lg-7'>
                  <div class='sec-title'>
                    <h3 class='title-big text-white wow animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                      Already part of Nepalaya!
                    </h3>
                    <p class='tour-sub-title'>Login to our E-PORTAL, an extension for quality education.</p>
                  </div>
                </div>
                <div class='col-lg-5'>
                  <div class='tour-btn'>
                    <a href='https://schoolworkspro.com/' class='link-btn btn-style-4 m-2'>
                      Go to E-PORTAL
                    </a>
                    <a href='#' class='link-btn btn-style-4 m-2'>
                      Get More Info
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class='course-content-sec sec-main bg-grey'>
            <div class='container'>
              <div class='row'>
                <div class='sec-title text-center'>
                  <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                    Course Content
                  </h3>
                </div>
              </div>

              <div class='row g-5 row-col-auto'>
                <div class='col-md-3 d-flex justify-content-center '>
                  <a href={Syllabus} target='_blank'>
                    <img src={PdfImage} alt='' style={{ height: '120px' }} />
                    <p class='text-center'>
                      <i>Syllabus</i>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section class='cta-sec sec-main bg-sec' style={{ 'background-image': `url(${about1});` }}>
            <div class='blue-overlay'></div>
            <div class='container'>
              <div class='row py-4'>
                <div class='col-lg-8 col-md-10 col-sm-12 mx-auto text-center'>
                  <div class='sec-title'>
                    <h5 class='title-small text-white wow animate__fadeInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                      Learn about your options.
                    </h5>
                    <h2 class='title-big text-white wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                      IS A CAREER IN MBS PROGRAM RIGHT FOR YOU? FIND OUT!
                    </h2>
                  </div>
                </div>
                <div class='col-lg-8 col-md-10 col-sm-12 mx-auto text-center wow animate__fadeInUp animate__delay-500ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                  <div class='cta-contact-box'>
                    <div class='cta-icon'>
                      <i class='fa fa-comments'></i>
                    </div>
                    <div class='cta-content'>
                      <div class='cta-desc text-white'>Talk to an Expert!</div>
                      <div class='cta-title '>
                        <a href='tel:015375203' class='text-white'>
                          {' '}
                          +977 – 1- 5375203 | 5234279{' '}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class='cta-btn mt-4'>
                    <a href='https://schoolworkspro.com/npl/admission' class='link-btn btn-style-2'>
                      Schedule Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class='carrer-pathway-sec sec-main'>
            <div class='container'>
              <div class='row'>
                <div class='col-lg-6'>
                  <div class='image image-top wow animate__fadeInLeft animate__delay-500ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                    <img src={about} alt='' />
                  </div>
                </div>
                <div class='col-lg-6'>
                  <div class='sec-title '>
                    <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
                      How this course will help the students?
                    </h3>
                  </div>
                  <div class='list'>
                    <ul style={{ 'text-alig': ' justify' }}>
                      <p>Skills you will gain after MBS Degree:</p>

                      <ul>
                        <li>&nbsp;Influential skills</li>
                        <li>Critical thinking skills</li>
                        <li>Entrepreneurial skills</li>
                        <li>&nbsp;Decision making skills</li>
                        <li>&nbsp;Sales &amp; marketing skills</li>
                        <li>Business communication &amp; interpersonal skills</li>
                        <li>Project management skills</li>
                        <li>Team building &amp; teamwork skills</li>
                        <li>Business and academic proposal writing skills</li>
                        <li>Leadership &amp; managerial</li>
                        <li>Business &amp; financial analysis skills</li>
                        <li>Budgeting &amp; credit control skills</li>
                        <li>&nbsp;Cost control &amp; planning</li>
                      </ul>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class='carrer-opportunities sec-main  wow animate__fadeInUp animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none;' }}>
            <div class='container'>
              <div class='row'>
                <div class='carrer-wrapper'>
                  <div class='carrer-content'>
                    <h3 class='title'>Carrer Pathways</h3>
                    <div class='content'>
                      <p></p>
                      <p>Financial Advisor | Financial Analyst | Banker | Stock Broker | Human Resource Manager | Investment Advisor | Business Consultant</p>
                      <p></p>
                    </div>
                  </div>
                  <div class='career-image '>
                    <img src={StudentImage} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default MBS
