import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import about1 from '../images/about1.jpeg'
import about2 from '../images/about2.jpeg'
import { Students } from '../ImageExport'
import WhyNepalaya from '../images/whynepalaya.png'
import ContentView from 'react-froala-wysiwyg/FroalaEditorView'
import naxios from '../nepAxios'
import { WHY_NEPALAYA } from '../constants/customApi'

function ReasonWhy() {
  const [about, setAbout] = useState(WHY_NEPALAYA)

  const getWhyPage = async () => {
    try {
      let result = await naxios.get('/pages/why-nepalaya')
      if (result.data.success) {
        setAbout(result.data.page)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getWhyPage()
  }, [])

  return (
    <div>
      <div class='sitewrapper'>
        <div class='pageheader-wrapper' style={{ backgroundImage: `url(${about1})` }}>
          <div class=' container'>
            <div class='row'>
              <div class='col-12 pageheader-inner'>
                <h2 class='page-title '>{about?.title}</h2>
                <ul class='bread-crumb'>
                  <li class='breadcrumb-item'>
                    <Link to={'/'}>Home</Link>
                  </li>
                  <li class='breadcrumb-item'>{about?.title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section class='about-us-sec sec-main pb-0'>
          <div class='container'>
            <div class='row'>
              <div class='col-lg-12'>
                <div class='sec-title text-center'>
                  <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'visible' }}>
                    {about?.title}
                  </h3>
                </div>
              </div>
              <div class='col-lg-12 col-md-12 ms-auto pb-5'>
                <div class='about-sec-content wow animate__fadeInLeft animate__delay-500ms' style={{ 'text-align': 'justify', visibility: 'visible', 'animation-name': 'fadeInLeft' }}>
                  <ContentView model={about?.content} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default ReasonWhy
