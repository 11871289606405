import React from 'react'
import { Link } from 'react-router-dom'
import ErrorImage from '../images/error.png'

function Error() {
  return (
    <div class='sitewrapper'>
      <link rel='stylesheet' href='//code.jquery.com/ui/1.13.0/themes/base/jquery-ui.css' />
      <link rel='stylesheet' href='/resources/demos/style.css' />

      <div class='container'>
        <div className='text-center'>
          <img src={ErrorImage} alt='Error' width='400px' />
        </div>
        <div class='row text-center'>
          <div class='col-lg-6 offset-lg-3 col-sm-6 offset-sm-3 col-12 p-3 error-main'>
            <div class='row'>
              <div class='col-lg-8 col-12 col-sm-10 offset-lg-2 offset-sm-1'>
                {/* Return to Home Page */}
                <div class='row'>
                  <div class='col-12'>
                    <h3 class='text-center'>
                      <Link to={'/'}>
                        <i class='fa fa-home'></i>
                        <span>Return to Home Page</span>
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error
