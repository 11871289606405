import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import Avatar from "../components/Avatar";

const NoticeLayout = ({ notice }) => {
  const [readMore, setReadMore] = useState(false);

  const circleStyle = {
    width: "35px",
    position: "relative",
    maxWidth: "35px",
    minWidth: "35px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    height: "35px",
    justifyContent: "center",
    backgroundColor: "#c3c1d9",
  };

  const initialsStyle = {};

  useEffect(() => {
    if (notice.noticeContent.length > 80) {
      setReadMore(true);
    }
  }, [notice]);

  const urlify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part) => {
      if (part.match(urlRegex)) {
        return <a href={part}>{part}</a>;
      }
      return part;
    });
  };

  return (
    <div
      className="card has-shadow border border-2 border-secondary"
      style={{
        backgroundColor: "#f4f5f7",
      }}
    >
      <div className="card-body">
        <div className="row">
          <div className="col-lg-10 col-md-10 col-sm-10">
            <h3>
              <b>{notice.noticeTitle}</b>
            </h3>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2 fs-4">
            {moment(notice.createdAt).isSame(moment(), "day") ? (
              <span className="badge bg-danger float-right">New</span>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="education_block_author">
              <div className="path-img">
                {notice.postedBy && notice.postedBy.userImage ? (
                  <img
                    src={`${
                      process.env.REACT_APP_SCHOOLWORKS_BASE_URI
                    }/uploads/users/${
                      notice.postedBy && notice.postedBy.userImage
                    }`}
                    className="img-fluid"
                    alt={
                      notice.postedBy &&
                      `Posted By ${notice.postedBy.firstname} ${notice.postedBy.lastname}`
                    }
                    style={{ width: "40px", height: "40px" }}
                  />
                ) : (
                  notice.postedBy && (
                    <Avatar
                      firstName={notice.postedBy.firstname}
                      lastName={notice.postedBy.lastname}
                      circleStyle={circleStyle}
                      initialsStyle={initialsStyle}
                    />
                  )
                )}
              </div>
              <div style={{ fontSize: "13px" }}>
                <span>
                  {notice.postedBy &&
                    `${notice.postedBy.firstname} ${notice.postedBy.lastname}`}
                </span>
                <span className="font-italic text-secondary ms-3">
                  <i className="fa fa-clock me-1"></i>
                  {moment(notice.createdAt).format("MMM DD, YYYY")}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col">
            <p
              className="wrapword"
              style={{
                color: "black",
              }}
            >
              {readMore
                ? `${notice.noticeContent.substring(0, 45)}...`
                : urlify(notice.noticeContent)}
            </p>
            {notice.filename && (
              <>
                <span>Attachment:</span>&nbsp;
                <a
                  href={`${process.env.REACT_APP_SCHOOLWORKS_BASE_URI}/uploads/files/${notice.filename}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  className="text-primary"
                >
                  Open <i className="fas fa-external-link-alt"></i>
                </a>
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className="card-footer"
        style={{ backgroundColor: "transparent", border: "none" }}
      >
        <div className="row">
          <div className="col-6 col-lg-6 col-md-6 col-sm-6">
            {notice.noticeContent.length > 80 && (
              <button
                className="btn btn-primary hover-effect btn-sm float-right py-0 btn-curve"
                onClick={() => setReadMore(!readMore)}
              >
                {readMore ? (
                  <span
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    Read More<i class="fa fa-angle-down ps-2"></i>
                  </span>
                ) : (
                  <span
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    Read Less<i class="fa fa-angle-up ps-2"></i>
                  </span>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeLayout;
