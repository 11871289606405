import React, { useContext, useState, useEffect } from 'react'
import NoticeLayout from './NoticeLayout'
import { NoticesContext } from '../contexts/Notices'
import { Code as SkeletonLoader } from 'react-content-loader'
import { Link } from 'react-router-dom'
import about1 from '../images/about1.jpeg'

const Notices = () => {
  const { isLoading, notices, currentPage, nextPage, previousPage, totalPages } = useContext(NoticesContext)

  const [allNotices, setAllNotices] = useState([])

  useEffect(() => {
    if (notices.length > 0) {
      setAllNotices(notices)
    }
  }, [notices])

  return (
    <>
      <div>
        <div class='sitewrapper'>
          <div class='pageheader-wrapper' style={{ backgroundImage: `url(${about1})` }}>
            <div class=' container'>
              <div class='row'>
                <div class='col-12 pageheader-inner'>
                  <h2 class='page-title '>News and Notices</h2>
                  <ul class='bread-crumb'>
                    <li class='breadcrumb-item'>
                      <Link to={'/'}>Home</Link>
                    </li>
                    <li class='breadcrumb-item'>News and Notices</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <section class='events-sec sec-main'>
            <div class='container mb-3'>
              <div class='row event-wrapper wow animate__fadeInDown animate__delay-200ms mt-5' style={{ visibility: 'visible', 'animation-name': 'fadeInDown;' }}>
                <div className='row justify-content-center'>
                  {isLoading ? (
                    <>
                      <div className='col-lg-6 col-md-8 col-sm-10 mx-auto'>
                        <div className='card'>
                          <div className='card-body'>
                            <SkeletonLoader />
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-8 col-sm-10 mx-auto'>
                        <div className='card'>
                          <div className='card-body'>
                            <SkeletonLoader />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : !notices.length ? (
                    <div className='col-lg-6 col-md-10 col-sm-12'>
                      <img src={`${process.env.PUBLIC_URL}/assets/image/no-content.svg`} alt='No Content' className='img-fluid no-data' style={{ height: '350px' }} />
                    </div>
                  ) : (
                    <>
                      {notices &&
                        notices.length > 0 &&
                        notices.map(notice => (
                          <div className='col-lg-4 col-md-10 col-sm-12 my-2' key={notice._id}>
                            <div className='edu_wrapper p-0'>
                              <NoticeLayout notice={notice} />
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </div>
              {/* pagination */}

              {/* Display Beautiful Pagination */}
              {allNotices.length > 0 && (
                <nav aria-label='Page navigation example' style={{ fontSize: '14px' }}>
                  <ul class='pagination justify-content-center'>
                    <li class={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                      <a
                        class='page-link'
                        onClick={previousPage}
                        style={{
                          cursor: 'pointer',
                          fontSize: '14px',
                        }}
                        onClick={previousPage}
                      >
                        <i class='me-2 fa fa-caret-left'></i>
                        Previous
                      </a>
                    </li>
                    <li class={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                      <a
                        class='page-link'
                        onClick={nextPage}
                        style={{
                          cursor: 'pointer',
                          fontSize: '14px',
                        }}
                        onClick={nextPage}
                      >
                        Next
                        <i class='ms-2 fa fa-caret-right'></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default Notices
