// Advisors
import AnthonyShull from './images/advisors/anthoyshull.jpg'
import DineshNeupane from './images/advisors/dineshneupane.jpg'
import HelmarGerrabn from './images/advisors/helmargerrabn.jpg'
import KamalDeepDhakal from './images/advisors/kamaldeepdhakal.jpg'
import KhilarajDahal from './images/advisors/khilarajdahal.JPG'
import OktayKoc from './images/advisors/oktaykoc.jpg'
import RajanThapa from './images/advisors/rajanthapa.jpg'
import RameshRayamajhi from './images/advisors/rameshrayamajhi.jpg'

// Experts
import AsgarAli from './images/expert/asgarali.jpeg'

// CEO
import SameerDhamala from './images/team/sameerdhamala.jpg'

// Team
import GovindaPrasadPaudyal from './images/team/govindaprasadpaudyal.jpg'
import UttamPrasadKayastha from './images/team/uttamprasadkayastha.jpg'
import RamHiraPathak from './images/team/ramhirapathak.jpg'
import ArmanAli from './images/team/armanali.jpg'
import KrishnaDotel from './images/team/krishnadotel.jpg'
import RajuKafle from './images/team/rajukafle.jpg'
import BijayMoktan from './images/team/bijaymoktan.jpg'
import ShreeMaharjan from './images/team/shreemaharjan.jpg'
import PushkalRajPant from './images/team/pushkalrajpant.jpg'

// Programs
import Science from './images/programs/science.jpg'
import Science1 from './images/programs/science1.jpeg'
import Science2 from './images/programs/science2.jpeg'
import Science3 from './images/programs/science3.jpeg'
import Science4 from './images/programs/science4.jpeg'
import Management from './images/programs/management.jpeg'
import Law from './images/programs/law.jpg'
import Humanities from './images/programs/humanities.jpg'
import CSIT from './images/programs/csit.jpeg'
import BCA from './images/programs/bca.jpeg'
import BBM from './images/programs/bbm.jpeg'
import BBS from './images/programs/bbs.jpeg'
import MBS from './images/programs/mbs.jpeg'

import Group1 from './images/students/group1.jpg'
import Group2 from './images/students/group2.jpg'
import TwoGirlReading from './images/students/twogirlreading.jpg'

// Talent Show
import TalentShow1 from './images/talentshow/talentshow1.jpg'
import TalentShow2 from './images/talentshow/talentshow2.jpg'
import TalentShow3 from './images/talentshow/talentshow3.jpg'
import TalentShow4 from './images/talentshow/talentshow4.jpg'
import TalentShow5 from './images/talentshow/talentshow5.jpg'
import TalentShow6 from './images/talentshow/talentshow6.jpg'
import TalentShow7 from './images/talentshow/talentshow7.jpg'
import TalentShow8 from './images/talentshow/talentshow8.jpg'
import TalentShow9 from './images/talentshow/talentshow9.jpg'
import TalentShow10 from './images/talentshow/talentshow10.jpg'
import TalentShow11 from './images/talentshow/talentshow11.jpg'
import TalentShow12 from './images/talentshow/talentshow12.jpg'
import TalentShow13 from './images/talentshow/talentshow13.jpg'
import TalentShow14 from './images/talentshow/talentshow14.jpg'
import TalentShow15 from './images/talentshow/talentshow15.jpg'

import Futsal1 from './images/sports/futsal1.jpg'
import Futsal2 from './images/sports/futsal2.jpg'
import Futsal3 from './images/sports/futsal3.jpg'
import Futsal4 from './images/sports/futsal4.jpg'
import Futsal5 from './images/sports/futsal5.jpg'
import Futsal6 from './images/sports/futsal6.jpg'
import Futsal7 from './images/sports/futsal7.jpg'
import Futsal8 from './images/sports/futsal8.jpg'
import Futsal9 from './images/sports/futsal9.jpg'
import Futsal10 from './images/sports/futsal10.jpg'
import BasketBall1 from './images/sports/basketball1.jpg'

let Advisor = {
  AnthonyShull,
  DineshNeupane,
  HelmarGerrabn,
  KamalDeepDhakal,
  KhilarajDahal,
  OktayKoc,
  RajanThapa,
  RameshRayamajhi,
}

let Expert = {
  AsgarAli,
}

let Team = {
  SameerDhamala,
  GovindaPrasadPaudyal,
  UttamPrasadKayastha,
  RamHiraPathak,
  ArmanAli,
  KrishnaDotel,
  RajuKafle,
  BijayMoktan,
  ShreeMaharjan,
  PushkalRajPant,
}

let Program = {
  Science,
  Science1,
  Science2,
  Science3,
  Science4,
  Management,
  Law,
  Humanities,
  CSIT,
  BCA,
  BBM,
  BBS,
  MBS,
}

let Students = {
  Group1,
  Group2,
  TwoGirlReading,
}

let TalentShow = {
  TalentShow1,
  TalentShow2,
  TalentShow3,
  TalentShow4,
  TalentShow5,
  TalentShow6,
  TalentShow7,
  TalentShow8,
  TalentShow9,
  TalentShow10,
  TalentShow11,
  TalentShow12,
  TalentShow13,
  TalentShow14,
  TalentShow15,
}

let Sports = {
  Futsal1,
  Futsal2,
  Futsal3,
  Futsal4,
  Futsal5,
  Futsal6,
  Futsal7,
  Futsal8,
  Futsal9,
  Futsal10,
  BasketBall1,
}

export { Advisor, Expert, Team, Program, Students, TalentShow, Sports }
