import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import about1 from '../images/about1.jpeg'
import { Program } from '../ImageExport'
import naxios from '../nepAxios'
import { useMemo } from 'react'
import { DEGREES } from '../constants/customApi'

function Academics() {
  const [degrees, setDegrees] = useState([])
  const [allCourses, setAllCourses] = useState([])
  const [currentSelection, setCurrentSelection] = useState('')

  const getAllAcademics = async () => {
    setDegrees(DEGREES)
    let courses = []
    DEGREES.forEach(deg => {
      courses = [...courses, ...deg.courses]
    })
    setAllCourses(courses)

    try {
      let result = await naxios.get('/academics')
      if (result.data.success) {
        setDegrees(result.data.degrees)
        let courses = []
        result.data.degrees.forEach(deg => {
          courses = [...courses, ...deg.courses]
        })
        setAllCourses(courses)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onDegreeClick = deg => {
    setCurrentSelection(deg)
  }

  const coursesDetail = useMemo(() => {
    let finalCourses = []
    let currentDegree = degrees
    if (currentSelection) {
      currentDegree = degrees.filter(deg => deg._id === currentSelection._id)
    }
    currentDegree.forEach(deg => {
      finalCourses = [...finalCourses, ...deg.courses]
    })
    return finalCourses
  }, [currentSelection, degrees])

  useEffect(() => {
    getAllAcademics()
  }, [])

  return (
    <div>
      <div class='sitewrapper'>
        <div class='pageheader-wrapper' style={{ backgroundImage: `url(${about1})` }}>
          <div class=' container'>
            <div class='row'>
              <div class='col-12 pageheader-inner'>
                <h2 class='page-title '>Academics</h2>
                <ul class='bread-crumb' id='academics'>
                  <li class='breadcrumb-item'>
                    <Link to={'/home'}>Home</Link>
                  </li>
                  <li class='breadcrumb-item'>Academics</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class='academics-pg sec-main'>
          <div class='container'>
            <div class='row'>
              <div class='col-lg-4'>
                <div class='academics-left'>
                  <ul class='nav nav-tabs academics-tabs ' id='myTab' role='tablist'>
                    <li
                      class='nav-item'
                      role='presentation'
                      onClick={() => {
                        onDegreeClick('')
                      }}
                    >
                      <span class={`nav-link ${currentSelection === '' && 'active'}`}>All Academics</span>
                    </li>

                    {degrees.map((deg, index) => {
                      return (
                        <li
                          key={deg._id}
                          class='nav-item'
                          role='presentation'
                          onClick={() => {
                            onDegreeClick(deg)
                          }}
                        >
                          <span class={`nav-link ${currentSelection._id === deg._id && 'active'}`}>{deg.title}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
              <div class='col-lg-8'>
                <div class='academics-right'>
                  <div class='programs-tab'>
                    <div class='sec-title'>
                      <h3 class='title-big'>{currentSelection ? currentSelection.title : 'All Academics'}</h3>
                    </div>
                    <div class='program-desc mb-4' style={{ 'text-align': 'justify' }}>
                      {currentSelection
                        ? currentSelection.description
                        : 'TRIBHUVAN UNIVERSITY (TU): NEPALAYA is an established private educational institute, and its entire Undergraduate/Bachelor Programs &amp; Master Program are affiliated with Tribhuvan University, Nepal. Tribhuvan University is the only one globally recognized government University of Nepal whilst joining Nepalaya for the further higher studies is one of the greatest achievements in a student’s life. NEPALAYA is offering undergraduate programs - BSc CSIT, BBM, BCA, BBS and Master Program – MBS under the affiliation of Tribhuvan University, Nepal.'}
                    </div>
                    <div class='programs-list-wrapper'>
                      <div class='row'>
                        {coursesDetail.map(cou => {
                          return (
                            <div class='col-lg-6'>
                              <div class='feature-block-1'>
                                <Link to={`/course/${cou.slug}`} class='feature-link'></Link>
                                <div class='feature-img'>
                                  <img src={`${process.env.REACT_APP_NEPALAYA_BASE}${cou.imageUrl}`} alt={cou.slug} />
                                </div>
                                <div class='feature-content'>
                                  <div class='feature-title'>
                                    <h4 class='program-title'>{cou.title}</h4>
                                    <span class='program-cat'>{cou.courseDuration}</span>
                                    <div class='program-view'>View Program</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Academics
