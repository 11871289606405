import React from 'react'
import SharingClass from '../images/sharingclass.jpeg'
import StudentImage from '../images/student-img.png'
import { Program } from '../ImageExport'

function Law() {
  return (
    <div>
      <div class='sitewrapper'>
        <div class='pageheader-wrapper pageheader-course' style={{ backgroundImage: `url(${Program.Law})` }}>
          <div class=' container'>
            <div class='row'>
              <div class='col-12 pageheader-inner'>
                <h2 class='page-title '>LAW</h2>
                <div class='course-header-desc'>
                  <div class='course-detail'>
                    <div class='icon'>
                      <i class='fas fa-history'></i>
                    </div>
                    <div class='title'>Duration</div>
                    <div class='content'>2 years</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='single-course-pg'>
          <section class='course-info-sec sec-main'>
            <div class='container'>
              <div class='row'>
                <div class='col-lg-9'>
                  <div class='course-info'>
                    <div class='title'>
                      <h3>LAW</h3>
                    </div>
                    <div class='content' style={{ 'text-align': 'justify' }}>
                      <p>
                        <strong>Higher Studies Pathways:&nbsp;</strong>
                        <br />
                        Any programs in Law, Management, Humanities &amp; Social Science, Education with few subject barriers in Computer Science degrees.
                      </p>

                      <p>
                        <strong>Course Highlight:&nbsp;</strong>
                        <br />
                        i.&nbsp;&nbsp; &nbsp;Compulsory Courses:
                        <br />
                        ii.&nbsp;&nbsp; &nbsp;Elective Courses:
                        <br />
                        &nbsp;
                      </p>
                    </div>
                  </div>
                </div>
                <div class='col-lg-3'>
                  <div class='course-extra-info'>
                    <div class='title'>
                      <h3>Course Info</h3>
                    </div>
                    <ul>
                      <li>
                        <h5>Course Duration</h5>
                        <span>2 years</span>
                      </li>
                      <li>
                        <h5>Program Duration</h5>
                        <span>2 Years</span>
                      </li>
                      <li>
                        <h5>Course Type</h5>
                        <span>Theory &amp; Practical</span>
                      </li>

                      <li>
                        <h5>Total Cost</h5>
                        <span>Rs. 45,000</span>
                      </li>
                    </ul>
                  </div>
                  <div class='course-contact'>
                    <div class='title'>
                      <h3>Get In Touch</h3>
                    </div>
                    <div class='content'>
                      <p>Have questions about studying at Nepalaya? We invite you to connect with our Admissions team to learn more.</p>
                    </div>
                    <a href='https://nepalaya.edu.np/contact' class='link-btn btn-style-5'>
                      Contact Us
                    </a>
                  </div>
                  <div class='apply-btn mb-5 mt-5'>
                    <a href='https://nepalaya.edu.np/admission' class='link-btn btn-style-1'>
                      Apply Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div class='tour-sec-big wow animate__fadeInLeft animate__delay-200ms' style={{ visibility: 'visible', 'animation-name': 'fadeInLeft' }}>
          <div class='container'>
            <div class='row align-items-center'>
              <div class='col-lg-7'>
                <div class='sec-title'>
                  <h3 class='title-big text-white wow animate__bounceInDown animate__delay-200ms' style={{ visibility: 'visible', 'animation-name': 'bounceInDown' }}>
                    Already part of Nepalaya!
                  </h3>
                  <p class='tour-sub-title'>Login to our E-PORTAL, an extension for quality education.</p>
                </div>
              </div>
              <div class='col-lg-5'>
                <div class='tour-btn'>
                  <a href='https://schoolworkspro.com/' class='link-btn btn-style-4 m-2'>
                    Go to E-PORTAL
                  </a>
                  <a href='#' class='link-btn btn-style-4 m-2'>
                    Get More Info
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section class='cta-sec sec-main bg-sec' style={{ 'background-image': 'url(https://nepalaya.edu.np/frontend/assets/image/cta-bg.jpg)' }}>
          <div class='blue-overlay'></div>
          <div class='container'>
            <div class='row py-4'>
              <div class='col-lg-8 col-md-10 col-sm-12 mx-auto text-center'>
                <div class='sec-title'>
                  <h5 class='title-small text-white wow animate__fadeInDown animate__delay-200ms' style={{ visibility: 'visible', 'animation-name': 'fadeInDown;' }}>
                    Learn about your options.
                  </h5>
                  <h2 class='title-big text-white wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'visible', 'animation-name': 'bounceInDown;' }}>
                    IS A CAREER IN LAW RIGHT FOR YOU? FIND OUT!
                  </h2>
                </div>
              </div>
              <div class='col-lg-8 col-md-10 col-sm-12 mx-auto text-center wow animate__fadeInUp animate__delay-500ms' style={{ visibility: 'visible', 'animation-name': 'fadeInUp' }}>
                <div class='cta-contact-box'>
                  <div class='cta-icon'>
                    <i class='fa fa-comments'></i>
                  </div>
                  <div class='cta-content'>
                    <div class='cta-desc text-white'>Talk to an Expert!</div>
                    <div class='cta-title '>
                      <a href='tel:015375203' class='text-white'>
                        {' '}
                        +977 – 1- 5375203 | 5234279{' '}
                      </a>
                    </div>
                  </div>
                </div>
                <div class='cta-btn mt-4'>
                  <a href='https://schoolworkspro.com/npl/admission' class='link-btn btn-style-2'>
                    Schedule Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class='carrer-opportunities sec-main  wow animate__fadeInUp animate__delay-200ms' style={{ visibility: 'visible', 'animation-name': 'fadeInUp' }}>
          <div class='container'>
            <div class='row'>
              <div class='carrer-wrapper'>
                <div class='carrer-content'>
                  <h3 class='title'>Carrer Pathways</h3>
                  <div class='content'>
                    <p></p>
                    <p>Judge | Advocate | Lawyer | Legal Advisor | Consular | Para Legal Practitioner</p>
                    <p></p>
                  </div>
                </div>
                <div class='career-image '>
                  <img src={StudentImage} alt='' />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Law
