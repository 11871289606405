import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_NEPALAYA_API_BASE_URI,
  headers: {
    Authorization: `${process.env.REACT_APP_AUTH_TOKEN}`,
  },
})

export default instance
